import axios, {AxiosPromise} from "axios";
// @ts-ignore
import PersonalForm, {PersonalFormErrors} from "@/types/PersonalForm";
// @ts-ignore
import {Stage} from "@/services/employee/EmployeeServise";
// @ts-ignore
import PassportForm from "@/types/PassportForm";

export interface StoreResponse {
    success: boolean
    nextStage: Stage|undefined
    errors: PersonalFormErrors|undefined
}

class PassportFormService {
    store(data: PassportForm): Promise<AxiosPromise> {
        return axios.post("employee/passport", data);
    }

    back(): Promise<AxiosPromise> {
        return axios.post("employee/passport/back");
    }
}

export default new PassportFormService();