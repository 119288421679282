<template>
  <h1>Заявка на подключение самозанятости</h1>
  <h3>Паспортные данные</h3>
  <form @submit.prevent class="uk-form-stacked">
    <div class="uk-margin">
      <label class="uk-form-label">Серия и номер паспорта</label>
      <input class="uk-input" type="text" placeholder="4509 235857" v-model="formValues.passport">
      <div class="" v-if="formErrors.passport">
        <span class="uk-text-danger" v-for="error in formErrors.passport">{{ error }}</span>
      </div>
    </div>
    <div class="uk-margin">
      <label class="uk-form-label">Дача выдачи</label>
      <input class="uk-input" type="date" v-model="formValues.issueDate">
      <div class="" v-if="formErrors.issueDate">
        <span class="uk-text-danger" v-for="error in formErrors.issueDate">{{ error }}</span>
      </div>
    </div>
    <div class="uk-margin">
      <label class="uk-form-label">Код подразделения</label>
      <input class="uk-input" type="text" placeholder="772-053" v-model="formValues.issuedOrgCode">
      <div class="" v-if="formErrors.issuedOrgCode">
        <span class="uk-text-danger" v-for="error in formErrors.issuedOrgCode">{{ error }}</span>
      </div>
    </div>
    <div class="uk-margin">
      <label class="uk-form-label">Паспорт выдан</label>
      <input class="uk-input" type="text" placeholder="ОВД ЗЮЗИНО Г. МОСКВЫ" v-model="formValues.issuedBy">
      <div class="" v-if="formErrors.issuedBy">
        <span class="uk-text-danger" v-for="error in formErrors.issuedBy">{{ error }}</span>
      </div>
    </div>
    <div class="uk-margin">
      <label class="uk-form-label">Дата рождения</label>
      <input class="uk-input" type="date" v-model="formValues.birthDate">
      <div class="" v-if="formErrors.birthDate">
        <span class="uk-text-danger" v-for="error in formErrors.birthDate">{{ error }}</span>
      </div>
    </div>
    <div class="uk-margin">
      <label class="uk-form-label">Место рождения</label>
      <input class="uk-input" type="text" placeholder="г Москва" v-model="formValues.birthAddress">
      <div class="" v-if="formErrors.birthAddress">
        <span class="uk-text-danger" v-for="error in formErrors.birthAddress">{{ error }}</span>
      </div>
    </div>

    <button class="uk-button uk-button-primary" @click="submitForm">Сохранить</button>
<!--    <button class="uk-button uk-button-default" @click="turnBack">Назад</button>-->
  </form>
</template>

<script lang="ts">
import {onMounted, ref} from "vue";
// @ts-ignore
import PassportFormService, {StoreResponse} from "@/services/employee/PassportFormService";
// @ts-ignore
import EmployeeService, {Stage, StageResponse} from "@/services/employee/EmployeeServise";
// @ts-ignore
import router from "@/router";

export default {
  setup() {
    const formValues = ref({
      passport: "",
      issueDate: "",
      issuedOrgCode: "",
      issuedBy: "",
      birthDate: "",
      birthAddress: "",
    })
    const formErrors = ref({})

    async function checkStage() {
      const response = await EmployeeService.stage();
      const data = response.data.data as StageResponse

      if (data.next != Stage.Passport) {
        await router.push({name: `employee/${data.next}`})
      }
    }

    async function submitForm() {
      try {
        const response = await PassportFormService.store(formValues.value);
        await router.push({name: `employee/${response.data.data.next}`})
      } catch (err) {
        const data = err.response.data as StoreResponse;
        if (data.errors) {
          formErrors.value = data.errors;
        }
      }
    }

    async function turnBack() {
      const response = await PassportFormService.back();
      await router.push({name: `employee/${response.data.data.next}`})
    }

    onMounted(async () => {
      await checkStage();
    })

    return {
      formValues,
      formErrors,
      submitForm,
    }
  }
}
</script>